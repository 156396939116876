import { defineStore } from 'pinia'
import { request } from '@/utils/http'
import cookie from 'js-cookie'
const indate = dayjs().formatDate()
const outdate = dayjs().add(1, 'day').formatDate()
let today = dayjs().formatDate()
const currencyHash: any = {
  AUD: 'AUD',
  CAD: 'CAD',
  CHF: 'CHF',
  CNY: 'CNY',
  DKK: 'DKK',
  EUR: '€',
  GBP: '£',
  HKD: 'HK$',
  IDR: 'IDR',
  INR: 'INR',
  JPY: 'JPY',
  KRW: '₩',
  MOP: 'MOP',
  MYR: 'MYR',
  NOK: 'NOK',
  NZD: 'NZD',
  PHP: 'PHP',
  RUB: 'RUB',
  SEK: 'SEK',
  SGD: 'SGD',
  THB: 'THB',
  TWD: 'NT$',
  USD: 'US$',
  VND: 'VND',
  RMB: 'CNY',
}

function getDateObj(date: string) {
  return {
    // title: dayjs(date).format('ddd, MMM DD'),
    value: dayjs(date).formatDate(),
  }
}

function generateKey(){
  const locale_key = cookie.get('locale')
  return `travelgo_home_hotel_20230718_${locale_key}`
}

export const useHotelStore = defineStore('hotelStore', {
  persist: {
    key: generateKey(),
    paths: ['city', 'indate', 'outdate', 'hotelCutOffTime'],
    storage: persistedState.localStorage,
    // storage: persistedState.cookiesWithOptions({
    //   sameSite: 'strict',
    //   path: '/',
    //   maxAge: 1000 * 60 * 60 * 24 * 30, // 30天缓存
    // }),
    afterRestore: async (ctx) => {
      if (process.client) {
        const { locale } = useI18n()
        const { t } = useLang()
        const lang = (locale.value || '').split('-')[0]
        const isZh = lang === 'zh'
        const isEn = lang === 'en'
        const isJa = lang === 'ja'

        // 返回刷新重置
        ctx.store.isZh = isZh
        ctx.store.isEn = isEn
        ctx.store.isJa = isJa

        // 默认城市 返回刷新重置
        if (ctx.store.city.def) {
          ctx.store.city = { cityId: '110020517', cityName: t('home.s_hotel_city_mangu'), showName: t('home.s_hotel_city_mangu'), cityType: '2', def: 1 }
        }
        ctx.store.city.showName = ctx.store.city.cityName

        // 日期过期处理
        await ctx.store.queryHotelCutOffTime()
        await ctx.store.getTimeZone()
        ctx.store.setDates(ctx.store.indate?.value, ctx.store.outdate?.value)
      }
    },
  },
  state: () => {
    const { locale } = useI18n()
    const { t } = useLang()
    const currency = useComCookie('currency') || ''
    const lang = (locale.value || '').split('-')[0]
    const isZh = lang === 'zh'
    const isEn = lang === 'en'
    const isJa = lang === 'ja'
    return {
      currency: currency.value || 'USD',
      isZh,
      isEn,
      isJa,
      /**
       * 城市
       */
      city: { cityId: '110020517', cityName: t('home.s_hotel_city_mangu'), showName: t('home.s_hotel_city_mangu'), cityType: '2', def: 1 },
      /**
       * 入住日期
       */
      indate: getDateObj(indate),
      /**
       * 离店日期
       */
      outdate: getDateObj(outdate),
      /**
       * 几天
       */
      days: 1,
      today,

      /**
       * 成人数量
       */
      adult: 2,
      /**
       * 儿童数据
       */
      child: 0,
      /**
       * 儿童年龄列表
       */
      ages: [],
      /**
       * 关键词
       */
      keywords: { name: '' },
      /**
       * 星级
       */
      stars: [
        { key: 2, value: '2', text: '≤2', select: 0 },
        { key: 3, value: '3', text: '3', select: 0 },
        { key: 4, value: '4', text: '4', select: 0 },
        { key: 5, value: '5', text: '5', select: 0 },
      ],

      /**
       * 是否显示城市选择器
       */
      citySelector: false,
      /**
       * 是否显示城市下拉搜索选择器
       */
      citySearchListSelector: false,
      /**
       * 是否显示日历选择器
       */
      calendarSelector: false,
      /**
       * 是否显示客人选择器
       */
      guestSelector: false,
      /**
       * 是否显示关键词选择器
       */
      keywordsSelector: false,
      /**
       * 是否显示关键词下拉搜索选择器
       */
      keywordsSearchListSelector: false,

      /**
       * 城市下拉推荐-热门城市
       */
      hotCityList: [],
      /**
       * 城市下拉推荐-热门国家的城市
       */
      hotContinentCityList: [],
      /**
       * 城市搜索下拉
       */
      searchList: [],

      /**
       * 关键词下拉推荐
       */
      hotKeywords: [],
      /**
       * 关键词下拉搜索
       */
      searchKeywordsObj: {
        kwList: [],
        cityList: [],
      },

      /**
       * 底部推荐的城市
       */
      recCitys: {
        index: 0,
        oCitys: [],
        cityList: [],
      },

      /**
       * 酒店凌晨房截止时间
       */
      hotelCutOffTime: {
        hour: 12,
        minute: 0,
      },
    }
  },
  getters: {
    /** 是否为酒店凌晨房 */
    isWeeRooms: (state) => {
      const startTimeInMinutes = dayjs(state.today).hour() * 60 + dayjs(state.today).minute()
      const hotelCutOffTimeInMinutes = state.hotelCutOffTime.hour * 60 + state.hotelCutOffTime.minute
      return startTimeInMinutes < hotelCutOffTimeInMinutes
    },
  },
  actions: {
    setCity(city: any) {
      if (city && city.cityId && city.cityName) {
        city = { ...city, def: 0 }
        city.showName = city.cityName
        this.city = city

        this.clearKeywords()
        this.clearStar()

        this.getTimeZone()
        this.getHotKeywords()
      }
    },
    setKeywords(kw: any) {
      kw = { ...kw }
      kw.name = kw.name || ''
      // kw.showName = kw.name
      if (kw.typeId && kw.filterId) {
        kw.filterList = `${kw.typeId}_${kw.filterId}`
      }
      this.keywords = kw
    },
    /**
     * 清除关键词
     */
    clearKeywords() {
      this.setKeywords({ name: '' })
    },
    setCitySelector(flag: boolean) {
      this.hideAllLayer()
      this.citySelector = flag
    },
    setCitySearchListSelector(flag: boolean) {
      this.hideAllLayer()
      this.citySearchListSelector = flag
    },
    setCalendarSelector(flag: boolean) {
      this.hideAllLayer()
      this.calendarSelector = flag
    },
    setGuestSelector(flag: boolean) {
      this.hideAllLayer()
      this.guestSelector = flag
    },
    setKeywordsSelector(flag: boolean) {
      this.hideAllLayer()
      this.keywordsSelector = flag
    },
    setKeywordsSearchListSelector(flag: boolean) {
      this.hideAllLayer()
      this.keywordsSearchListSelector = flag
    },
    setDates(indate: string, outdate: string) {
      if (!indate || (indate && dayjs(indate).isBefore(today, 'day') && !this.isWeeRooms)) {
        indate = today
      }
      if (!outdate || (outdate && !dayjs(outdate).isAfter(indate))) {
        outdate = dayjs(indate).add(1, 'day').formatDate()
      }

      this.indate = getDateObj(indate)
      this.outdate = getDateObj(outdate)
      this.days = dayjs(outdate).diff(indate, 'day')
    },
    getDateObj,
    hideAllLayer() {
      this.citySelector = false
      this.citySearchListSelector = false
      this.calendarSelector = false
      this.guestSelector = false
      this.keywordsSelector = false
      this.keywordsSearchListSelector = false
    },

    /**
     * 选择星级
     */
    selectStar(n: any) {
      n.select = n.select === 1 ? 0 : 1
    },
    /**
     * 清空星级
    */
    clearStar() {
      this.stars.forEach((n) => {
        n.select = 0
      })
    },

    /**
     * 获取时区
     */
    async getTimeZone() {
      today = dayjs().formatDate()
      const id = this.city.cityId
      const res: any = await http({
        url: '/tapi/timeZoneInfo',
        baseURL: this.getHotelApiHost(),
        headers: this.getHotelApiHeaders(),
        query: {
          id,
          type: 'region',
          time: Date.now(),
        },
      })
      const timeZone = res?.data?.timeZone

      if (timeZone) {
        const localTimezone = new Date().getTimezoneOffset() * 60 * 1000
        const targetTimezone = +timeZone * 60 * 60 * 1000
        const now = dayjs(Date.now() + localTimezone + targetTimezone)
        if (now.isValid()) {
          today = dayjs(now).format('YYYY-MM-DD HH:mm:ss')
          // today = dayjs('2023-08-28').formatDate() // cl测试
          this.today = today
          this.setDates(this.indate?.value, this.outdate?.value)
        }
        console.log(id, res.data.localTime, now.format('YYYY-MM-DD HH:mm:ss'))
      }
    },
    /**
   * 获取时区
   */
    async queryHotelCutOffTime() {
      const res: any = await http({
        url: '/sea_shell/sea_octopus_api/hotel/hotelCutOffTime',
        baseURL: this.getHotelApiHost(),
        headers: this.getHotelApiHeaders(),
        method: 'POST',
        body: {},
      })
      const cutOffTime = res?.body
      if (cutOffTime) {
        const times = cutOffTime.split(':')
        this.hotelCutOffTime = {
          hour: +times[0],
          minute: +times[1] || 0,
        }
      }
    },
    /**
     * 获取热门城市
     */
    async getHotCitys() {
      const res: any = await request({
        url: '/sea_shell/sea_octopus_api/hotel/getHotCityList',
        method: 'POST',
        data: {
          clientInfo: JSON.stringify({
            deviceId: useComCookie('H5CookieId').value,
          }),
        },
        includeClientInfo: false,
      })
      this.hotCityList = res?.cityList || []
      this.hotContinentCityList = res?.continentCityList || []
    },
    async getHotCitys2() {
      const res: any = await http({
        url: '/tapi/v2/getHotCityList',
        baseURL: 'https://www.hopegoo.com',
        headers: this.getHotelApiHeaders(),
        query: {
          time: Date.now(),
        },
      })
    },
    /**
     * 获取热门关键词
     */
    async getHotKeywords() {
      const res: any = await http({
        url: '/tapi/v2/filter',
        baseURL: this.getHotelApiHost(),
        headers: this.getHotelApiHeaders(),
        query: {
          city: this.city.cityId,
          time: Date.now(),
        },
      })

      this.hotKeywords = res?.data?.hotFilters || []
    },
    /**
     * 获取推荐城市
     */
    async getRecCitys() {
      const res: any = await http({
        url: '/pcapi/city/query',
        baseURL: this.getHotelApiHost(),
        query: {
          time: Date.now(),
        },
      })
      const citys = res?.data || []
      citys.isok = 1
      this.recCitys.oCitys = citys
      this.recCitys.cityList = citys
      if (citys.length) {
        this.recCitys.index = 0
        this.getRecHotels(citys[0], 0)
      }
    },
    /**
     * 获取推荐的酒店
     */
    async getRecHotels(city: any, index: number) {
      if (city?.hotelList?.length) {
        return
      }

      const res: any = await http({
        url: '/pcapi/hotel/query',
        baseURL: this.getHotelApiHost(),
        query: {
          cityId: city.cityId,
          cityType: city.cityType,
          inDate: this.indate.value,
          outDate: this.outdate.value,
          time: Date.now(),
        },
      })

      const list = res?.data?.hotelList || []
      const num = 7 // 7
      if (list.length > num) {
        list.length = num
      }
      const n: any = this.recCitys.cityList[index]
      if (n) {
        n.hotelList = list
        n.isok = 1
      }
    },
    setRecCityList() {
      const cityList: any = this.recCitys.oCitys.filter((n: any) => n && n.cityId !== this.city.cityId)
      cityList.unshift({ ...this.city })
      this.recCitys.cityList = cityList
      this.recCitys.index = 0
    },
    /**
     * 获取酒店接口请求头
     */
    getHotelApiHeaders() {
      const deviceid = useComCookie('H5CookieId') || ''
      let lang = 'en-us'

      if (this.isZh) {
        lang = 'zh-tw'
      }
      else if (this.isJa) {
        lang = 'ja-jp'
      }

      const tmapi = this.getIntl() ? 'i-hopegoopc' : 'hopegoopc'
      return {
        'tmapi-client': tmapi,
        'deviceid': deviceid.value || '',
        // 时区  例如：-7、9
        'timeZone': -(new Date().getTimezoneOffset() / 60),
        // 语言(带地区)  例如：en-us、zh-cn
        'lang': lang || 'en-us',
        // 币种三字码  例如：CNY、USD
        'currency': this.currency || 'USD',
        'appfrom': 20016,
      }
    },
    /**
     * 获取配置的酒店链接域名
     */
    getBaseUrl() {
      const runtimeConfig = useRuntimeConfig()
      const url = runtimeConfig.public.envData.VITE_HOTEL_BASEURL || ''
      const { locale } = useI18n()
      const lang = locale.value
      return `${url}/${lang}`
    },
    getHotelApiHost() {
      const runtimeConfig = useRuntimeConfig()
      const host = runtimeConfig.public.envData.VITE_HOTEL_API_HOST || ''
      return host
    },
    /**
     * 是否国际 {string} 1-国际
     */
    getIntl(city?: any) {
      city = city || this.city
      return (+city.cityType === 1 || +city.cityType === 2) ? '1' : ''
    },
    getAdultUnit(num: number) {
      return num > 1 ? 'home.s_hotel_guests_adult_s' : 'home.s_hotel_guests_adult_1'
    },
    getChildtUnit(num: number) {
      return num > 1 ? 'home.s_hotel_guests_child_s' : 'home.s_hotel_guests_child_1'
    },
    getNightUnit(num: number) {
      return num > 1 ? 'home.s_hotel_date_night_s' : 'home.s_hotel_date_night_1'
    },
    showDateStr(date: string) {
      if (this.isZh) {
        return dayjs(date).locale('zh-hk').format('M月DD日 ddd')
      }

      if (this.isJa) {
        return dayjs(date).locale('ja-jp').format('M月DD日 ddd')
      }

      return dayjs(date).format('ddd, MMM DD')
    },
    showCurrency(currency: string) {
      return currencyHash[currency || this.currency] || ''
    },
    setCityShowName() {
      this.city.showName = this.city.cityName
    },
  },
})
